import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Grid,
  Typography,
  Avatar,
  AppBar,
  Toolbar,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import PayCreditCard from "./PayCreditCard";
import PayGCash from "./PayGCash";
import Review from "./Review";
import CCSummary from "./CCSummary";
// import Summary from "./Summary";
import http from "../service/httpService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 20,
  },
  paperLeft: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paperCenter: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  gridCenter: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    borderColor: "black",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  image: {
    position: "relative",
    height: 130,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Review", "Pay", "Done"];
  const [paymentData, setPaymentData] = useState(null);
  const [payType, setPayType] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getToken();
  }, []);

  async function getToken() {
    try {
      await http
        .post(`payment/auth`, {
          patient_id: props.match.params.patient_id,
          payment_id: props.match.params.payment_id,
        })
        .then((response) => {
          if (!response.data.error) {
            console.log(response.data);
            setPaymentData(response.data);
            setIsLoading(false);
          } else {
            if (response.data.error_type === "transaction_closed") {
              window.location.replace("/locked");
            } else {
              window.location.replace("/");
            }
          }
        });
    } catch (error) {
      window.location.replace("/");
    }
  }

  const handleNext = (type, params = null) => {
    setPayType(type);
    setActiveStep(1);
  };

  const handleBack = (type = null, steps = 0) => {
    setPayType(type || "");
    setActiveStep(steps);
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid container spacing={3}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">CWApp Payment</Typography>
          </Toolbar>
        </AppBar>
        {isLoading ? (
          <div style={{ direction: "row", justifyContent: "center", padding: 10, width: "100%" }}>
            <Skeleton variant="text" />
            <Skeleton variant="circle" width={40} height={40} style={{ marginTop: 10 }} />
            <Skeleton variant="rect" height={60} style={{ marginTop: 10 }} />
            <Skeleton variant="rect" height={30} style={{ marginTop: 10 }} />
            <Skeleton variant="rect" height={20} style={{ marginTop: 10 }} />
            <Skeleton variant="rect" height={10} style={{ marginTop: 10 }} />
            <Skeleton variant="rect" height={10} style={{ marginTop: 10 }} />
            <Skeleton variant="rect" height={80} style={{ marginTop: 10 }} />
          </div>
        ) : (
          <>
            <Grid item xs={12}>
              <Paper className={classes.paperCenter}>
                <Grid container>
                  <Grid item xs={3} container direction="column" justify="center">
                    <Avatar
                      alt="Patient Image"
                      src={
                        paymentData.patient.img_path
                          ? paymentData.patient.img_path
                          : require("../asset/image/no-img.png")
                      }
                      className={classes.avatar}
                    />
                    <Typography style={{ fontSize: 14 }}>
                      Hi! {paymentData.patient.lastname}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                </Grid>
              </Paper>
              <div style={{ paddingTop: 10 }}>
                {paymentData && activeStep === 0 && (
                  <Review
                    paymentData={{ ...paymentData }}
                    handleNext={(type, param) => handleNext(type, param)}
                  />
                )}
                {paymentData && activeStep === 1 && payType === "creditcard" && (
                  <PayCreditCard
                    paymentData={{ ...paymentData }}
                    handleBack={(type, steps) => handleBack(type, steps)}
                    handleNext={(type, param) => handleNext(type, param)}
                  />
                )}
                {paymentData && activeStep === 1 && payType === "gcash" && (
                  <PayGCash
                    paymentData={{ ...paymentData }}
                    handleBack={(type, steps) => handleBack(type, steps)}
                    handleNext={(type, param) => handleNext(type, param)}
                  />
                )}
                {paymentData && activeStep === 1 && payType === "ccsummary" && (
                  <CCSummary
                    paymentData={{ ...paymentData }}
                    handleBack={(type, steps) => handleBack(type, steps)}
                    handleNext={(type, param) => handleNext(type, param)}
                  />
                )}
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default withRouter(Home);
