import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import error404 from "../asset/image/404.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 100,
  },
}));

const Error404 = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Typography variant="h5">CWApp Patient Portal!</Typography>
        <img alt="Cwapp-error" src={error404} />
        <p style={{ color: "red", textAlign: "center" }}>
          Something went wrong!<b></b> Either you cancelled the payment,<b></b> or error happens
          while validating your card.
          <b></b> Please try again!
        </p>
        <Typography style={{ paddingTop: 10 }}>
          Visit <span style={{ color: "blue" }}>https://cwassistant.com</span> for help
        </Typography>

        <Typography>You can now close this window</Typography>
      </Grid>
    </Container>
  );
};

export default Error404;
