import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  Collapse,
  IconButton,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined";
import { FormatNumber } from "../helper/helper";
import gcashBanner from "../asset/image/gcas-banner.png";
import { baseUrl } from "../config.json";
import CloseIcon from "@material-ui/icons/Close";

import http from "../service/httpService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PayGCash = (props) => {
  const classes = useStyles();
  const { paymentData, handleBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [alertState, setAlertState] = useState(false);
  const [alertStateMsg, setAlertStateMsg] = useState("");

  const createSource = async () => {
    try {
      setIsLoading(true);
      const sourceData = {
        payment_id: paymentData.payment.payment_id,
        amount: paymentData.payment.amount,
        type: "gcash",
        redirect_success: `${baseUrl}success/gcash/${paymentData.payment.patient_id}/${paymentData.payment.payment_id}/${paymentData.token}`,
        redirect_failed: `${baseUrl}failed/gcash`,
        email: paymentData.patient.email,
        name: paymentData.patient.firstname + " " + paymentData.patient.lastname,
        phone: paymentData.patient.mobile,
      };
      await http
        .post(`createsource`, sourceData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${paymentData.token}`,
          },
        })
        .then((response) => {
          if (!response.data.error) {
            window.location.replace(response.data.source_url);
            setIsLoading(false);
          } else {
            setAlertState(true);
            setAlertStateMsg(
              "Unable to create payment, please contact contact CWapp Team https://cwassistant.com"
            );
            setIsLoading(false);
            console.cler();
          }
        });
    } catch (error) {
      console.log(error.response);
      setAlertState(true);
      setAlertStateMsg(
        "Unable to create payment, please contact contact CWapp Team https://cwassistant.com"
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Alert severity="success">
        <b>Pay by GCash</b>
      </Alert>

      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ marginTop: 15 }}
      >
        <Collapse in={alertState} style={{ marginTop: 5 }}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertState(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>Error</AlertTitle>
            {alertStateMsg}
          </Alert>
        </Collapse>

        <Typography style={{ marginBottom: 15 }}>
          You are about to pay <b>(&#8369; {FormatNumber(paymentData.payment.amount)})</b> using{" "}
          <b>GCash</b>.
        </Typography>
        <img alt="cwapp-checkout" src={gcashBanner} style={{ width: "100%" }} />
        <Typography style={{ marginBottom: 15, marginTop: 15 }}>
          Click Next to continue...
        </Typography>

        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<NavigateBeforeOutlinedIcon />}
            onClick={() => handleBack()}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<NavigateNextOutlinedIcon />}
            onClick={() => {
              setAlertState(false);
              createSource();
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PayGCash;
