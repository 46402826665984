import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import payGCash from "../asset/image/pay-gcash.png";
import payCreditCard from "../asset/image/pay-cc.png";
import { FormatNumber } from "../helper/helper";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 20,
  },
  paperLeft: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paperCenter: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  gridCenter: {
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    borderColor: "black",
  },

  image: {
    position: "relative",
    height: 130,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.1,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "2px solid currentColor",
        color: "rgba(0,0,0,.7)",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "50% !important", // Overrides inline-style
      height: 100,
    },
    [theme.breakpoints.down("lg")]: {
      width: "50% !important", // Overrides inline-style
      height: 100,
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
    objectFit: "cover",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

const Review = (props) => {
  const classes = useStyles();
  const { paymentData, handleNext } = props;

  useEffect(() => {}, []);
  return (
    <>
      <Alert
        severity="success"
        action={
          <Typography variant="h5" style={{ marginRight: 10 }}>
            (&#8369; {FormatNumber(paymentData.payment.amount)})
          </Typography>
        }
      >
        <b>Amount to pay:</b>
      </Alert>
      <Table aria-label="simple table" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Ref. Id
            </TableCell>
            <TableCell align="left">{paymentData.payment.payment_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell align="left">
              {paymentData.patient.firstname} {paymentData.patient.lastname}{" "}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Email
            </TableCell>
            <TableCell align="left">{paymentData.patient.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Mobile
            </TableCell>
            <TableCell align="left">{paymentData.patient.mobile}</TableCell>
          </TableRow>

          {/* <TableRow>
            <TableCell component="th" scope="row">
              Address
            </TableCell>
            <TableCell align="left">
              {paymentData.patient.town_city}, {paymentData.patient.province}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Doctor
            </TableCell>
            <TableCell align="left">
              {paymentData.doctor.firstname} {paymentData.doctor.lastname}{" "}
              {paymentData.doctor.degree}
            </TableCell>
          </TableRow> */}

          <TableRow>
            <TableCell component="th" scope="row">
              Item
            </TableCell>
            <TableCell align="left">{paymentData.payment.item}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* <Grid container spacing={0} direction="row" justify="center" style={{ marginTop: 10 }}>
        <Grid item xs={5} sm={5} md={5}>
          <Typography style={{ fontSize: 16 }}>Amount to pay.</Typography>
          <Paper
            style={{
              justify: "center",
              backgroundColor: "#EDF7ED",
              padding: 20,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Typography variant="h5">&#8369; {FormatNumber(paymentData.payment.amount)}</Typography>
          </Paper>
          <Typography style={{ fontSize: 12 }}></Typography>
        </Grid>
        <Grid item xs={7} sm={7} md={7} style={{ padding: 8 }}>
         
        </Grid>
      </Grid> */}

      <div style={{ paddingTop: 15 }}>
        <Typography>Select Payment Type</Typography>
        <div
          style={{ display: "flex", flexWrap: "wrap", minWidth: 300, width: "100%", marginTop: 10 }}
        >
          <ButtonBase
            focusRipple
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            onClick={() => handleNext("creditcard")}
          >
            <span
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${payCreditCard})`,
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                CreditCard
                <span className={classes.imageMarked} />
              </Typography>
            </span>
          </ButtonBase>

          <ButtonBase
            focusRipple
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            onClick={() => handleNext("gcash")}
            //
          >
            <span
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${payGCash})`,
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                GCash
                <span className={classes.imageMarked} />
              </Typography>
            </span>
          </ButtonBase>
        </div>
      </div>
    </>
  );
};

export default Review;
