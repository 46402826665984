function toTitleCase(str) {
  var lcStr = str.trim().toLowerCase();
  return lcStr.replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}

function toLowerCase(str) {
  return str.trim().toLowerCase();
}

function toUpperCase(str) {
  return str.trim().toUpperCase();
}

function generateUID(len, charSet) {
  charSet = charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var randomString = "";
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
}

function isEmail(email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

function FormatNumber(n) {
  let x = Number(n);
  var parts = x.toFixed(2).split(".");
  var num = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + (parts[1] ? "." + parts[1] : "");
  return num;
}

const daysWord = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thrusday", "Friday", "Saturday"];

export { toTitleCase, toLowerCase, toUpperCase, daysWord, generateUID, isEmail, FormatNumber };
