import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined";
import http from "../service/httpService";

import { baseUrl } from "../config.json";

import { Alert } from "@material-ui/lab";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     paddingTop: 20,
//   },
// }));

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     paddingTop: 20,
//   },
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: "#fff",
//   },
// }));

const CCSummary = (props) => {
  // const classes = useStyles();
  const { paymentMethodData, handleBack, xTra, KYe2O } = props;

  const createPaymentIntent = async (pmData) => {
    // Get the payment intent id from the client key
    try {
      await http
        .post(
          `createintent`,
          {
            pmData: { ...pmData },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${KYe2O}`,
            },
          }
        )
        .then((response) => {
          if (!response.error) {
            // console.log(response.data);
            // console.log(paymentMethodData);
            // console.log(publicToken);
            attachMethod(response.data.attachment);
          } else {
            // return response()->json(['error' => true, 'error_type' => 'unexpected_error', 'error_details' => 'Something went wrong!']);
            console.log(response.data);
          }
        });
    } catch (error) {
      console.log(error.response);
      // props.history.push("/");
    }
  };

  const attachMethod = async (attachment) => {
    let return_url = `${baseUrl}ccc/${paymentMethodData.data.attributes.metadata.patient_id}/${paymentMethodData.data.attributes.metadata.payment_id}/${KYe2O}`;

    //   $attachmentData = [
    //     'client_key' => $intentData['attributes']['client_key'],
    //     'payment_method_id' => $request->pmData['data']['id']
    // ];

    let piID = attachment.client_key.split("_client")[0];
    await http
      .post(
        `https://api.paymongo.com/v1/payment_intents/${piID}/attach`,
        {
          data: {
            attributes: {
              client_key: attachment.client_key,
              payment_method: attachment.payment_method_id,
              return_url: return_url,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${xTra}`,
          },
        }
      )
      .then(function (response) {
        var paymentIntent = response.data.data;
        var paymentIntentStatus = paymentIntent.attributes.status;
        console.log(response);

        if (paymentIntentStatus === "awaiting_next_action") {
          window.location.replace(paymentIntent.attributes.next_action.redirect.url);
          //   setThreeDUrl(paymentIntent.attributes.next_action.redirect.url);
          //   setModalState(true);
          // render your modal for 3D Secure Authentication since next_action has a value. You can access the next action via paymentIntent.attributes.next_action.
        } else if (paymentIntentStatus === "succeeded") {
          console.log(
            `You already received your customer's payment. You can show a success message from this condition.`
          );
        } else if (paymentIntentStatus === "awaiting_payment_method") {
          console.log(
            "The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message."
          );
        }
      })
      .catch((error) => {
        // console.clear();
        console.log(error.response);
      });
  };

  return (
    <>
      <Alert severity="success">
        <b>Pay by Credit Card</b>
      </Alert>

      <Typography style={{ marginTop: 20, marginBottom: 20 }}>
        <b>Payment Summary</b>
      </Typography>
      <Paper>
        <Table aria-label="simple table" size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" width={160}>
                Card Number
              </TableCell>
              <TableCell align="left">
                xxxxxxxxxxxx{paymentMethodData.data.attributes.details.last4}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Expiration - CVC
              </TableCell>
              <TableCell align="left">
                {paymentMethodData.data.attributes.details.exp_month} /
                {paymentMethodData.data.attributes.details.exp_year} -
                {paymentMethodData.data.attributes.details.cvc}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Typography style={{ marginTop: 20, marginBottom: 20 }}>
        <b>Personal Details</b>
      </Typography>
      <Paper style={{ marginTop: 15, marginBottom: 15 }}>
        <Table aria-label="simple table" size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" width={120}>
                Card Holder:
              </TableCell>
              <TableCell align="left">{paymentMethodData.data.attributes.billing.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Email:
              </TableCell>
              <TableCell align="left">{paymentMethodData.data.attributes.billing.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Contact
              </TableCell>
              <TableCell align="left">{paymentMethodData.data.attributes.billing.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Address
              </TableCell>
              <TableCell align="left">
                {paymentMethodData.data.attributes.billing.address.line1}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Town/City
              </TableCell>
              <TableCell align="left">
                {paymentMethodData.data.attributes.billing.address.city} ,{" "}
                {paymentMethodData.data.attributes.billing.address.state}{" "}
                {paymentMethodData.data.attributes.billing.address.postal_code}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Button
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<NavigateBeforeOutlinedIcon />}
          onClick={() => handleBack("creditcard", 1)}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => createPaymentIntent(paymentMethodData)}
        >
          Pay Now
        </Button>
      </Grid>
    </>
  );
};

export default CCSummary;
