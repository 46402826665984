import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import clockAlert from "../asset/image/clock-alert.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 100,
  },
}));

const TransComplete = (props) => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Typography variant="h5">CWApp Patient Portal!</Typography>
        <img alt="cwapp-alert" src={clockAlert} />
        <Typography variant="h6">This transaction is already closed!</Typography>

        <Typography>You can now close this window</Typography>

        <Typography style={{ paddingTop: 10 }}>
          Visit <span style={{ color: "blue" }}>https://cwassistant.com</span> for help
        </Typography>
      </Grid>
    </Container>
  );
};

export default withRouter(TransComplete);
