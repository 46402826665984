import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TextField,
  Collapse,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined";
import http from "../service/httpService";
import { toTitleCase } from "../helper/helper";
import { baseUrl } from "../config.json";
import { withRouter } from "react-router-dom";

import CloseIcon from "@material-ui/icons/Close";

import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    smallText: {
      fontSize: 10,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PayCreditCard = (props) => {
  const classes = useStyles();
  const { paymentData, handleBack } = props;
  const [formData, setFormData] = useState({
    cc: { value: "", error: false },
    m: { value: "", error: false },
    y: { value: "", error: false },
    cvc: { value: "", error: false },
  });
  const [alertSate, setAlertState] = useState(false);
  const [alertState2, setAlertState2] = useState(false);
  const [alertState2Msg, setAlertState2Msg] = useState("");
  const [cardErrors, setCardErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setFormData({ ...formData, [e.target.id]: { value: e.target.value } });
    setAlertState(false);
    setAlertState2(false);
  };

  const validateData = () => {
    let temp = {
      cc: { value: "", error: false },
      m: { value: "", error: false },
      y: { value: "", error: false },
      cvc: { value: "", error: false },
    };

    let hasError = false;
    Object.entries(formData).map(([key, value]) => {
      let err = false;
      if (key === "m") {
        if (value.value === "" || value.value.length < 1) {
          err = true;
          hasError = true;
        }
      } else if (key === "y") {
        if (value.value === "" || value.value.length < 2) {
          err = true;
          hasError = true;
        }
      } else if (key === "cc") {
        if (value.value === "" || value.value.length < 16) {
          err = true;
          hasError = true;
        }
      } else if (key === "cvc") {
        if (value.value === "" || value.value.length < 3) {
          err = true;
          hasError = true;
        }
      }
      temp[key].value = value.value;
      temp[key].error = err;
    });
    setFormData(temp);

    if (!hasError) {
      let card_details = {
        card_number: temp.cc.value,
        exp_month: Number(temp.m.value),
        exp_year: Number(temp.y.value),
        cvc: temp.cvc.value,
      };
      createPaymentIntent(card_details);
    }
  };

  const createPaymentIntent = async (card_details) => {
    setIsLoading(true);
    try {
      await http
        .post(
          `createintent`,
          {
            payment_id: paymentData.payment.payment_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${paymentData.token}`,
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            const intent_data = response.data.attachment;
            console.log(response.data);
            createPaymentMethodData(card_details, intent_data);
          } else {
            console.clear();
            // return response()->json(['error' => true, 'error_type' => 'unexpected_error', 'error_details' => 'Something went wrong!']);
            console.log(response.data);
            setAlertState2(true);
            setAlertState2Msg(
              "Unable to create payment, please contact contact CWapp Team https://cwassistant.com"
            );
            setIsLoading(false);
          }
        });
    } catch (error) {
      console.log(error.response);
      setAlertState2(true);
      setAlertState2Msg(
        "Unable to create payment, please contact contact CWapp Team https://cwassistant.com"
      );
      setIsLoading(false);
    }
  };

  const createPaymentMethodData = (card_details, intent_data) => {
    let paymentMethodData = {
      data: {
        attributes: {
          billing: {
            address: {
              city: paymentData.patient.town_city,
              country: "PH",
              line1: paymentData.patient.street,
              postal_code: paymentData.patient.zip,
              state: paymentData.patient.province,
            },
            email: paymentData.patient.email,
            name: paymentData.patient.firstname + " " + paymentData.patient.lastname,
            phone: paymentData.patient.mobile,
          },
          details: {
            ...card_details,
          },
          type: "card",
          metadata: {
            payment_id: paymentData.payment.payment_id,
            patient_id: paymentData.payment.patient_id,
            amount: paymentData.payment.amount,
            description: paymentData.doctor.consultation_desc,
          },
        },
      },
    };

    // card_number: "5363433783409819",
    // exp_month: 11,
    // exp_year: 27,
    // cvc: "316",

    // console.log(card_details);
    // console.log(paymentMethodData);
    // let paymentMethodData = {
    //   data: {
    //     attributes: {
    //       billing: {
    //         address: {
    //           city: paymentData.patient.town_city,
    //           country: "PH",
    //           line1: paymentData.patient.street,
    //           line2: "",
    //           postal_code: paymentData.patient.zip,
    //           state: paymentData.patient.province,
    //         },
    //         email: paymentData.patient.email,
    //         name: paymentData.patient.firstname + " " + paymentData.patient.lastname,
    //         phone: paymentData.patient.mobile,
    //       },
    //       details: { ...card_details },
    //       type: "card",
    //       metadata: {
    //         payment_id: paymentData.payment.payment_id,
    //         patient_id: paymentData.payment.patient_id,
    //         amount: paymentData.payment.amount,
    //         description: paymentData.doctor.consultation_desc,
    //       },
    //     },
    //   },
    // };

    processPaymentMethod(paymentMethodData, intent_data);
  };

  const processPaymentMethod = async (paymentMethodData, intent_data) => {
    await http
      .post(
        "https://api.paymongo.com/v1/payment_methods",
        {
          ...paymentMethodData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${paymentData.xZa2odS}`,
          },
        }
      )
      .then(function (response) {
        const attachments = { ...intent_data, payment_method_id: response.data.data.id };
        attachMethod(attachments);
      })
      .catch((error) => {
        setIsLoading(false);
        console.clear();
        setAlertState(true);
        setCardErrors(error.response.data.errors);
      });
  };

  const attachMethod = async (attachments) => {
    console.log(attachments.client_key);
    let return_url = `${baseUrl}ccc/${paymentData.payment.patient_id}/${paymentData.payment.payment_id}/${paymentData.token}`;

    let pi = attachments.client_key.split("_client")[0];
    await http
      .post(
        `https://api.paymongo.com/v1/payment_intents/${pi}/attach`,
        {
          data: {
            attributes: {
              client_key: attachments.client_key,
              payment_method: attachments.payment_method_id,
              return_url: return_url,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${paymentData.xZa2odS}`,
          },
        }
      )
      .then(function (response) {
        var paymentIntent = response.data.data;
        var paymentIntentStatus = paymentIntent.attributes.status;
        console.log(response);

        if (paymentIntentStatus === "awaiting_next_action") {
          window.location.replace(paymentIntent.attributes.next_action.redirect.url);
          // render your modal for 3D Secure Authentication since next_action has a value. You can access the next action via paymentIntent.attributes.next_action.
        } else if (paymentIntentStatus === "succeeded") {
          window.location.replace(
            `${baseUrl}ccc/${paymentData.payment.patient_id}/${paymentData.payment.payment_id}/${paymentData.token}`
          );
          // props.history.push(
          //   `/ccc/${paymentData.payment.patient_id}/${paymentData.payment.payment_id}/${paymentData.token}`
          // );
        } else if (paymentIntentStatus === "awaiting_payment_method") {
          setIsLoading(false);
          setAlertState2(true);
          setAlertState2Msg(paymentIntent.attributes.last_payment_error);
          console.clear();
          console.log(
            "The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message."
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.clear();
        setAlertState(true);
        setCardErrors(error.response.data.errors);
      });
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Alert severity="success">
        <b>Pay by Credit Card</b>
      </Alert>

      <Collapse in={alertSate} style={{ marginTop: 5 }}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertState(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Error</AlertTitle>
          {cardErrors.map((item, index) => {
            return (
              <Typography key={index} style={{ fontSize: 12 }}>
                {toTitleCase(item.detail.replace("details.", "").replace("_", " "))}
              </Typography>
            );
          })}
        </Alert>
      </Collapse>

      <Collapse in={alertState2} style={{ marginTop: 5 }}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertState2(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Error</AlertTitle>
          {alertState2Msg}
        </Alert>
      </Collapse>

      <TextField
        required
        error={formData.cc.error}
        fullWidth
        id="cc"
        value={formData.cc.value}
        inputProps={{ maxLength: 16 }}
        onInput={(e) => handleInputChange(e)}
        label="Credit Card Number"
        helperText={formData.cc.error ? "Incorrect entry." : ""}
        variant="outlined"
        size="small"
        style={{ marginTop: 20 }}
      />
      <Grid container style={{ marginBottom: 20, marginTop: 10 }} spacing={2}>
        <Grid item xs={6}>
          <img
            alt="paycreditcard"
            src={require("../asset/image/credit_card.png")}
            style={{ width: "90%" }}
          ></img>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                error={formData.m.error}
                id="m"
                value={formData.m.value}
                inputProps={{ maxLength: 2 }}
                onInput={(e) => handleInputChange(e)}
                label="Month"
                helperText={formData.m.error ? "Incorrect entry." : ""}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={formData.y.error}
                id="y"
                value={formData.y.value}
                inputProps={{ maxLength: 2 }}
                onInput={(e) => handleInputChange(e)}
                label="Year"
                helperText={formData.y.error ? "Incorrect entry." : ""}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={formData.cvc.error}
                id="cvc"
                value={formData.cvc.value}
                inputProps={{ maxLength: 3 }}
                onInput={(e) => handleInputChange(e)}
                label="CVC"
                helperText={formData.cvc.error ? "Incorrect entry." : ""}
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        <Button
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<NavigateBeforeOutlinedIcon />}
          onClick={() => handleBack()}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<NavigateNextOutlinedIcon />}
          onClick={() => validateData()}
        >
          Next
        </Button>
      </Grid>
    </>
  );
};

export default withRouter(PayCreditCard);
