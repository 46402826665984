import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import gcashFailed from "../asset/image/gcash-success.png";
import http from "../service/httpService";
import { socketUrl } from "../config.json";
import socketIOClient from "socket.io-client";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 100,
  },
}));

var socket = socketIOClient(socketUrl);
const SourcePaymentSuccess = (props) => {
  const paytype = props.match.params.paytype;
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    socket.on("connect", function () {
      console.log("Connected to server");
    });
    socket.on("disconnect", function () {
      console.log("Disconnected from server");
    });
    walletCheckOut();
  }, []);

  async function walletCheckOut() {
    try {
      await http
        .post(
          `walletcheckout`,
          {
            payment_id: props.match.params.payment_id,
            patient_id: props.match.params.patient_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${props.match.params.token}`,
            },
          }
        )
        .then((response) => {
          if (!response.error) {
            injectDataToTenant();
          } else {
            setIsLoading(false);
            props.history.push("/");
            // console.log(response);
          }
        });
    } catch (error) {
      // console.log(error.response);
      props.history.push("/");
    }
  }

  async function injectDataToTenant() {
    try {
      await http
        .post(
          `payment/injectvalidate`,
          {
            payment_id: props.match.params.payment_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${props.match.params.token}`,
            },
          }
        )
        .then((response) => {
          socket.emit("newPPAppointment", response.data.emitData);
          setIsLoading(false);
        });
    } catch (error) {
      props.history.push("/");
    }
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        {isLoading ? (
          <Grid container direction="column" justify="center" alignItems="center">
            <div>
              <Skeleton variant="rect" height={70} style={{ marginTop: 10 }} />
            </div>
            <Skeleton variant="circle" width={200} height={200} style={{ marginTop: 10 }} />
            <div
              style={{
                direction: "row",
                justify: "center",
                alignItems: "center",
                padding: 10,
                width: "100%",
              }}
            >
              <Skeleton variant="rect" height={70} style={{ marginTop: 10 }} />
              <Skeleton variant="rect" height={40} style={{ marginTop: 10 }} />
              <Skeleton variant="rect" height={30} style={{ marginTop: 10 }} />
              <Skeleton variant="rect" height={30} style={{ marginTop: 10 }} />
            </div>
          </Grid>
        ) : (
          <>
            <Typography variant="h5">CWApp Patient Portal!</Typography>
            <img alt="cwapp-checkout" src={paytype === "gcash" ? gcashFailed : ""} />

            <Typography variant="h6" style={{ paddingTop: 10 }}>
              Thank you!
            </Typography>
            <Typography style={{ paddingTop: 10 }}>
              Visit <span style={{ color: "blue" }}>https://cwassistant.com</span> for help
            </Typography>
            <Typography style={{ fontSize: 14, paddingTop: 10 }}>
              You can now close this window
            </Typography>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default withRouter(SourcePaymentSuccess);
