import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./component/Home";
import Error404 from "./component/Error404";
import CCCheckOut from "./component/CCCheckOut";
import TransComplete from "./component/TransComplete";
import SourcePaymentFailed from "./component/SourcePaymentFailed";
import SourcePaymentSuccess from "./component/SourcePaymentSuccess";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/pay/:patient_id/:payment_id" component={Home} />
        <Route exact path="/ccc/:patient_id/:payment_id/:token" component={CCCheckOut} />
        <Route exact path="/locked" component={TransComplete} />
        <Route exact path="/failed/:paytype" component={SourcePaymentFailed} />
        <Route
          exact
          path="/success/:paytype/:patient_id/:payment_id/:token"
          component={SourcePaymentSuccess}
        />
        <Route exact path="*" component={Error404} />
      </Switch>
    </Router>
  );
};

export default App;
