import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import gcashFailed from "../asset/image/gcash-failed.png";
import http from "../service/httpService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 100,
  },
}));

const SourcePaymentFailed = (props) => {
  let paytype = props.match.params.paytype;
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Typography variant="h5">CWApp Patient Portal!</Typography>
        <img alt="cwapp-checkout" src={paytype === "gcash" ? gcashFailed : ""} />
        <p style={{ color: "red", textAlign: "center" }}>
          Error! Either cancelled payment<br></br>or payment has been disapproved by{" "}
          <b>{paytype.toUpperCase()}</b>
        </p>
        <Typography style={{ paddingTop: 10 }}>
          Visit <span style={{ color: "blue" }}>https://cwassistant.com</span> for help
        </Typography>
        <Typography style={{ fontSize: 14, paddingTop: 10 }}>
          You can now close this window
        </Typography>
      </Grid>
    </Container>
  );
};

export default SourcePaymentFailed;
