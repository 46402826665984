import axios from "axios";
import { apiUrl } from "../config.json";

const http = axios.create({ baseURL: apiUrl });
http.interceptors.response.use(null, (error) => {
  if (error.message && error.message.responseCancelled) {
    console.log("response cancelled");
  } else {
    const expectedError =
      error.response && error.response.status >= 400 && error.response.status < 500;

    if (!expectedError) {
      alert("An unexpected error occurred. Please try again.");
    }
  }
  return Promise.reject(error);
});
export default http;
