import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import checkImg from "../asset/image/check.png";
import alertImg from "../asset/image/clock-alert.png";
import http from "../service/httpService";
import { socketUrl } from "../config.json";
import socketIOClient from "socket.io-client";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 100,
  },
}));

var socket = socketIOClient(socketUrl);

const CCCheckOut = (props) => {
  const classes = useStyles();
  const [statusMessage, setStatusMessage] = useState({ level: 0, msg: "" });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    socket.on("connect", function () {
      console.log("Connected to server");
    });
    socket.on("disconnect", function () {
      console.log("Disconnected from server");
    });

    const creditCardIntentCheker = async () => {
      try {
        await http
          .post(
            `cccheckout`,
            {
              payment_id: props.match.params.payment_id,
              patient_id: props.match.params.patient_id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${props.match.params.token}`,
              },
            }
          )
          .then((response) => {
            if (!response.error) {
              // console.log(response.data.data);
              let intent = response.data.data;
              let intentStatus = intent.attributes.status;
              if (intentStatus === "succeeded") {
                injectDataToTenant();
              } else if (intentStatus === "awaiting_payment_method") {
                let lastError = intent.attributes.last_payment_error.failed_message;
                setStatusMessage({ level: 0, msg: lastError });
                setIsLoading(false);
              } else {
                setStatusMessage({
                  level: 0,
                  msg: "Something went wrong, unable to validate your card!",
                });
                setIsLoading(false);
              }
            } else {
              setStatusMessage({
                level: 0,
                msg: "General error!, unable to validate your card!",
              });
              setIsLoading(false);
            }
          });
      } catch (error) {
        // console.log(error.response);
        props.history.push("/");
      }
    };

    creditCardIntentCheker();
  }, []);

  // async function creditCardIntentCheker() {
  //   try {
  //     await http
  //       .post(
  //         `cccheckout`,
  //         {
  //           payment_id: props.match.params.payment_id,
  //           patient_id: props.match.params.patient_id,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `bearer ${props.match.params.token}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         if (!response.error) {
  //           console.log(response.data.data);
  //           let intent = response.data.data;
  //           let intentStatus = intent.attributes.status;
  //           if (intentStatus === "succeeded") {
  //             injectDataToTenant();
  //           } else if (intentStatus === "awaiting_payment_method") {
  //             let lastError = intent.attributes.last_payment_error.failed_message;
  //             setStatusMessage({ level: 0, msg: lastError });
  //             setIsLoading(false);
  //           } else {
  //             setStatusMessage({
  //               level: 0,
  //               msg: "Something went wrong, unable to validate your card!",
  //             });
  //             setIsLoading(false);
  //           }
  //         } else {
  //           setStatusMessage({
  //             level: 0,
  //             msg: "General error!, unable to validate your card!",
  //           });
  //           setIsLoading(false);
  //         }
  //       });
  //   } catch (error) {
  //     // console.log(error.response);
  //     props.history.push("/");
  //   }
  // }

  async function injectDataToTenant() {
    try {
      await http
        .post(
          `payment/injectvalidate`,
          {
            payment_id: props.match.params.payment_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${props.match.params.token}`,
            },
          }
        )
        .then((response) => {
          socket.emit("newPPAppointment", response.data.emitData);
          setStatusMessage({ level: 1, msg: "Thank your for your payment!" });
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error.response);
      props.history.push("/");
    }
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        {isLoading ? (
          <Grid container direction="column" justify="center" alignItems="center">
            <div>
              <Skeleton variant="rect" height={70} style={{ marginTop: 10 }} />
            </div>
            <Skeleton variant="circle" width={200} height={200} style={{ marginTop: 10 }} />
            <div
              style={{
                direction: "row",
                justify: "center",
                alignItems: "center",
                padding: 10,
                width: "100%",
              }}
            >
              <Skeleton variant="rect" height={70} style={{ marginTop: 10 }} />
              <Skeleton variant="rect" height={40} style={{ marginTop: 10 }} />
              <Skeleton variant="rect" height={30} style={{ marginTop: 10 }} />
              <Skeleton variant="rect" height={30} style={{ marginTop: 10 }} />
            </div>
          </Grid>
        ) : (
          <>
            <Typography variant="h5">CWApp Patient Portal!</Typography>
            <img alt="cwapp-checkout" src={statusMessage.level === 0 ? alertImg : checkImg} />
            <Typography variant="h6" style={{ paddingTop: 10 }}>
              {statusMessage.msg}
            </Typography>
            <Typography style={{ paddingTop: 10 }}>
              Visit <span style={{ color: "blue" }}>https://cwassistant.com</span> for help
            </Typography>
            <Typography style={{ fontSize: 14, paddingTop: 10 }}>
              You can now close this window
            </Typography>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default withRouter(CCCheckOut);
